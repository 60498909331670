<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <div class="sb_school_dashboard_header">
          <h1>Dashboard</h1>
        </div>
      </template>
      <template v-slot:right> </template>
    </sb-page-header>

    <div class="sb_dashboard_students-report">
      <sb-call-out style="width: 300px">
        <template v-slot:left> 👨‍🎓 </template>
        <template v-slot:right>
          Aantal actieve gebruikers:
          <strong
            v-if="get(dashboardData, 'getOrganisationById')"
            style="font-size: 24px"
          >
            {{ dashboardData.getOrganisationById.takenSeats }}
          </strong>
        </template>
      </sb-call-out>

      <sb-card v-if="quickStartInfo" class="sb_dashboard_students-report_card">
        <h3 class="sb_dashboard_students-report_card-title">Snelstartgids</h3>
        <p class="sb_dashboard_students-report_card-description">
          {{ quickStartInfo.title }}
        </p>
        <i-button id="btn_snelstart_gids" @click="downloadQuickStart"
          >Download</i-button
        >
      </sb-card>
    </div>

    <sb-divider title="Recente sessies" />
    <div class="sb_school_dashboard_indent">
      <p class="sb_school_dashboard_annotation">
        Laatste {{ activeSessionsLimit }} sessies.
      </p>
      <sb-table-2
        :config="sessionsTableConfig"
        :data="recentSessions"
        :loading="$apollo.queries.dashboardData.loading"
        :with-navigation="false"
      />
    </div>

    <sb-divider title="Laatste sessie per begeleider" />
    <div class="sb_school_dashboard_indent">
      <sb-coach-activity-table />
    </div>

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbCallOut from '@/components/SbCallOut';
import SbTable2 from '@/components/SbTable2/SbTable2.vue';
import gql from 'graphql-tag';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from '@/components/SbTable2/TableConfig';
import SbCard from '@/components/SbCard.vue';
import { quickStartInfoMixin } from '@/mixins/quickStartInfoMixin';
import SbCoachActivityTable from '@/components/SbCoachActivityTable/SbCoachActivityTable.vue';
import { mapActions } from 'vuex';

export default {
  name: 'DashboardStudentsReport',
  components: {
    SbPageHeader,
    SbCallOut,
    SbCoachActivityTable,
    SbTable2,
    SbCard,
  },

  mixins: [
    quickStartInfoMixin,
    nonReactiveMembersMixin(() => {
      const sessionsTableConfig = new TableConfig({
        columns: [
          {
            key: 'trackCoach',
            header: 'Begeleider',
            width: 200,
            data: (row) =>
              row.track.users.find((u) => u.role === 'TRACK_COACH')?.user
                .fullName,
          },
          {
            key: 'trackStudent',
            header: 'Leerling',
            width: 200,
            data: (row) =>
              row.track.users.find((u) => u.role === 'TRACK_STUDENT')?.user
                .fullName,
          },
          {
            key: 'track',
            header: 'Leesniveau',
            data: (row) => row.track.readingLevel ?? 'n.v.t.',
          },
          {
            key: 'problemFocus',
            header: 'Problematiek',
            data: (row) => row.problemFocus.title,
          },
          {
            key: 'cards',
            header: 'Kaarten',
            width: 300,
            data: (row) =>
              row.cards.map((exercise) => exercise.card.title).join(', '),
          },
          {
            key: 'createdAt',
            header: 'Datum',
            data: (row) => {
              const date = new Date(row.createdAt);
              return [
                date.toLocaleDateString(),
                date.toLocaleTimeString(),
              ].join(', ');
            },
          },
          {
            key: 'finishedAt',
            header: 'Status',
            data: (row) => (row.finishedAt ? 'Voltooid' : 'Bezig'),
          },
        ],
      });
      return { sessionsTableConfig };
    }),
  ],

  data: () => ({ activeSessionsLimit: 10 }),

  computed: {
    recentSessions() {
      return this.dashboardData?.sessions?.edges.map(({ node }) => node) || [];
    },
  },

  mounted() {
    const currentTourKey = 'MANAGER_LOGIN';
    this.enqueueTour(currentTourKey);
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
  },

  apollo: {
    dashboardData: {
      variables() {
        return {
          sessionsFirst: this.activeSessionsLimit,
          sessionsFilter: {},
          sessionsOrderBy: { createdAt: 'DESC' },
        };
      },

      update: (data) => data,

      query() {
        return gql`query SchoolDashboardData($sessionsFirst: Int, $sessionsFilter: SessionsFilter, $sessionsOrderBy: SessionsOrderBy) {
          sessions(first: $sessionsFirst, filter: $sessionsFilter, orderBy: $sessionsOrderBy) {
            edges {
              node {
                id
                createdAt
                finishedAt
                track {
                  id
                  readingLevels
                  users {
                    role
                    user {
                      id
                      fullName
                    }
                  }
                }
                problemFocus {
                  id
                  title
                }
                cards {
                  id
                  card{
                    id
                    title
                  }
                }
              }
            }
          }
          getOrganisationById(id: "${this.$user.resourceId}") {
            id
            takenSeats
          }
        }`;
      },
    },

    // getOrganisationById: {
    //   query() {
    //     return gql`query School_Dashboard_getOrganisationById {
    //       getOrganisationById(id: "${this.$user.resourceId}") {
    //         id
    //         takenSeats
    //       }
    //     }`;
    //   },
    // },
  },
};
</script>

<style lang="scss">
.sb_school_dashboard {
  &_indent {
    padding-left: 20px;
  }

  &_annotation {
    color: $brand-gray;
  }
}

.sb_dashboard_students-report {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 1.5rem;

  &_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &_card-title {
    margin-bottom: 0.8rem;
  }

  &_card-description {
    color: $brand-gray;
  }
}
</style>
