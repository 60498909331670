import gql from 'graphql-tag';

export const quickStartInfoMixin = {
  computed: {
    _fileField() {
      return {
        COACH: 'coachFile',
        MANAGER: 'schoolManagerFile',
        STUDENT: 'studentFile',
      }[this.$user.role];
    },
  },

  methods: {
    downloadQuickStart() {
      if (!this._fileField || !this.quickStartInfo) return;
      const a = document.createElement('a');
      a.download = this.quickStartInfo.mediaItemUrl.split('/').pop();
      a.href = this.quickStartInfo.mediaItemUrl;
      a.target = '_blank';
      a.style.display = 'none';
      document.body.append(a);
      a.click();
      document.body.removeChild(a);
    },
  },

  apollo: {
    quickStartInfo: {
      skip() {
        return !this._fileField;
      },

      update(data) {
        return data.globalContent?.quickStart?.[this._fileField];
      },

      query() {
        return gql`
          query QuickStartInfo {
            globalContent {
              quickStart {
                ${this._fileField} {
                  title
                  mediaItemUrl
                }
              }
            }
          }
        `;
      },
    },
  },
};
