<template>
  <div>
    <highcharts :options="chartOptions" />
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  components: {
    Highcharts: Chart
  },
  data() {
    return {
      chartOptions: {
        chart: {
          style: {
            fontFamily: 'Nunito'
          }
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: { // don't display the dummy year
            month: '%b',
            year: '%b'
          },
          labels: {
            style: {
              fontSize: '14px'
            }
          }
        },
        yAxis: [
          { // Primary yAxis
            labels: {
              format: '{value}',
              style: {
                color: '#3fcefb',

                fontSize: '14px'
              }
            },
            title: {
              text: 'Aantal geslaagde Leerlingen',
              style: {
                color: '#3fcefb',
                fontSize: '14px'
              }
            },
            opposite: false

          },
          { // Secondary yAxis
            labels: {
              format: '{value}',
              style: {
                color: '#4e3ffb',

                fontSize: '14px'
              }
            },
            title: {
              text: 'Slagingspercentage',
              style: {
                color: '#4e3ffb',
                fontSize: '14px'
              }
            },
            opposite: true

          }
        ],
        colors: [
          '#3fcefb',
          '#4e3ffb',
          '#d53ffb',
          '#fb3f6c',
          '#24CBE5',
          '#64E572',
          '#FF9655',
          '#FFF263',
          '#6AF9C4'
        ],
        legend: {
          layout: 'horizontal',
          align: 'right',
          margin: 40,
          verticalAlign: 'top',
          backgroundColor: '#FFFFFF'
        },
        title: false,
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            lineWidth: 3,
            marker: {
              symbol: 'circle',
              lineWidth: 4,
              lineColor: null // inherit from series
            }
          }
        },
        series: [
          {
            name: 'Aantal geslaagde Leerlingen',
            yAxis: 0,
            data: [
              [
                Date.UTC(2020, 0),
                80
              ],
              [
                Date.UTC(2020, 1),
                100
              ],
              [
                Date.UTC(2020, 2),
                130
              ],
              [
                Date.UTC(2020, 3),
                90
              ],
              [
                Date.UTC(2020, 4),
                70
              ],
              [
                Date.UTC(2020, 5),
                110
              ],
              [
                Date.UTC(2020, 6),
                30
              ]
            ]
          },
          {
            name: 'Slagingspercentage',
            yAxis: 1,
            data: [
              [
                Date.UTC(2020, 0),
                80
              ],
              [
                Date.UTC(2020, 1),
                75
              ],
              [
                Date.UTC(2020, 2),
                90
              ],
              [
                Date.UTC(2020, 3),
                65
              ],
              [
                Date.UTC(2020, 4),
                80
              ],
              [
                Date.UTC(2020, 5),
                84
              ],
              [
                Date.UTC(2020, 6),
                83
              ]
            ]
          }
        ]
      }
    };
  }
};
</script>

<style>

</style>
