<template>
  <div style="opacity: 0.2">
    <!-- <Breadcrumb>
      <BreadcrumbItem>Dashboard</BreadcrumbItem>
    </Breadcrumb> -->

    <sb-page-header>
      <template v-slot:left>
        <h1>Dashboard</h1>
      </template>
      <template v-slot:right>
        <dropdown placement="bottom-end">
          <i-button type="primary" ghost>
            Acties
            <icon type="md-more" />
          </i-button>

          <dropdown-menu slot="list" style="width: 180px">
            <sb-dropdown-button>
              Menu item
              <sb-icon icon-id="icon_close" />
            </sb-dropdown-button>
            <sb-dropdown-button>
              Menu item
              <sb-icon icon-id="icon_close" />
            </sb-dropdown-button>
          </dropdown-menu>
        </dropdown>
      </template>
    </sb-page-header>

    <div class="sb_view_online-dashboard_kpis" style="max-width: 1200px">
      <div class="sb_view_online-dashboard_kpis_item">
        <div class="sb_view_online-dashboard_kpis_item_icon">
          <sb-icon icon-id="icon_graduated" />
        </div>
        <div class="sb_view_online-dashboard_kpis_item_left">
          <router-link
            to="/admin/online/results"
            class="sb_view_online-dashboard_kpis_item_inner"
            tag="button"
          >
            <div class="sb_view_online-dashboard_kpis_item_title">Geslaagd</div>
            <div class="sb_view_online-dashboard_kpis_item_subtitle">
              afgelopen 6 maanden
            </div>
            <div class="sb_view_online-dashboard_kpis_item_value">3.205</div>
          </router-link>
        </div>
        <div class="sb_view_online-dashboard_kpis_item_right">
          <router-link
            to="/admin/online/results"
            class="sb_view_online-dashboard_kpis_item_inner"
            tag="button"
          >
            <div class="sb_view_online-dashboard_kpis_item_title">
              Slagingspercentage
            </div>
            <div class="sb_view_online-dashboard_kpis_item_subtitle">
              afgelopen 6 maanden
            </div>
            <div class="sb_view_online-dashboard_kpis_item_value">89%</div>
          </router-link>
        </div>
      </div>
      <div class="sb_view_online-dashboard_kpis_item">
        <div class="sb_view_online-dashboard_kpis_item_icon">
          <sb-icon icon-id="icon_messages" />
        </div>
        <router-link
          to="/admin/online/messages/inbox"
          class="sb_view_online-dashboard_kpis_item_inner"
          tag="button"
        >
          <div class="sb_view_online-dashboard_kpis_item_inner">
            <div class="sb_view_online-dashboard_kpis_item_title">
              Openstaande berichten
            </div>
            <div class="sb_view_online-dashboard_kpis_item_subtitle">
              in de inbox
            </div>
            <div class="sb_view_online-dashboard_kpis_item_value v_vibe-red">
              13
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <sb-spacer height="40" />

    <sb-graph-graduates style="max-width: 1600px" />

    <sb-spacer height="40" />

    <row :gutter="25" style="max-width: 1200px">
      <i-col :xs="{ span: 24 }" :lg="{ span: 12 }" style="margin: 0 0 40px 0">
        <sb-insight-list
          title="Oefen frequentie"
          subtitle="Niet goed geoefend"
          description="Leerlingen die 3 dagen of meer niet hebben geoefend."
          :data="dashboardFrequencyData"
          vibe="negative"
          :show-numbering="false"
          :header-map="{
            name: 'Naam',
            days: 'Dagen',
          }"
          :click-triggers="{
            name: (val) =>
              $router.push({
                name: 'ResourceManagementStudentsStudent',
                params: { studentId: val.id },
              }),
          }"
        />
      </i-col>
      <i-col :xs="{ span: 24 }" :lg="{ span: 12 }" style="margin: 0 0 40px 0">
        <sb-insight-list
          title="Berichten"
          subtitle="Weinig berichten geopend"
          description=""
          :data="dashboardFrequencyData"
          vibe="negative"
          :show-numbering="true"
          :header-map="{
            name: 'Naam',
            days: 'Berichten',
          }"
          :click-triggers="{
            name: (val) =>
              $router.push({
                name: 'ResourceManagementStudentsStudent',
                params: { studentId: val.name },
              }),
          }"
        />
      </i-col>
    </row>

    <sb-spacer height="400" />
  </div>
</template>

<script>
import SbInsightList from '@/components/SbInsightList';
import SbPageHeader from '@/components/SbPageHeader';
import SbGraphGraduates from '@/components/SbGraphGraduates';

import dashboardFrequencyData from '@/data/dummy/dashboardFrequency.json';

export default {
  name: 'OnlineDashboard',
  components: {
    SbInsightList,
    SbPageHeader,
    SbGraphGraduates,
  },
  data() {
    return {
      dashboardFrequencyData,
    };
  },
};
</script>

<style lang="scss">
.sb_view_online-dashboard {
  //
  //  KPIS
  //
  &_kpis {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 20px;

    &_item {
      position: relative;
      display: flex;
      padding: 40px 0;
      overflow: hidden;
      background: $brand-lightest-gray;
      border: solid 1px $brand-light-gray;
      border-radius: 10px;

      &_icon {
        position: absolute;
        top: 0;
        left: -40px;

        svg {
          font-size: 200px;
          opacity: 0.05;
        }
      }

      &_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 30px;
        user-select: none;
      }

      &_title {
      }

      &_subtitle {
        font-size: 14px;
        color: $brand-gray;
      }

      &_value {
        margin: 20px 0 0 0;
        font-size: 40px;
        font-weight: 600;

        &.v_vibe-red {
          color: $brand-vibe-red;
        }

        &.v_vibe-green {
          color: $brand-vibe-green;
        }
      }

      &_left {
        width: 50%;
        max-width: 50%;
        border-right: solid 1px $brand-light-gray;
      }

      &_right {
        width: 50%;
        max-width: 50%;
      }
    }
  }
}

button.sb_view_online-dashboard_kpis_item_inner {
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.5;
  }
}
</style>
