<template>
  <div class="container">
    <sb-page-header>
      <template v-slot:left>
        <h1>Welkom, {{ get(me, 'fullName') }}</h1>
      </template>
    </sb-page-header>

    <div class="flex">
      <div class="left">
        <sb-divider title="Selecteer een kaart" class="flex_left_divider" />

        <sb-cards-list
          ref="cardsList"
          v-model="selectedCard"
          :student-ids="[$user.id]"
          mode="assigned-cards"
          :mix-completed="true"
          :show-exercise-status-enabled="true"
        />

        <sb-spacer height="200" />

        <sb-bottom-fixed-buttons
          class="bottom-bar"
          :with-side-menu-indent="false"
        >
          <template #right>
            <i-button
              :disabled="!selectedCard"
              type="primary"
              size="large"
              @click="handleStart"
            >
              Start oefening
            </i-button>
          </template>
        </sb-bottom-fixed-buttons>
      </div>

      <div class="right">
        <sb-card v-if="quickStartInfo" class="sb_dashboard_student_card">
          <h3 class="sb_dashboard_student_card_title">Snelstartgids</h3>
          <p class="sb_dashboard_student_card_description">
            {{ quickStartInfo.title }}
          </p>
          <i-button @click="downloadQuickStart">Download</i-button>
        </sb-card>
      </div>
    </div>
  </div>
</template>

<script>
import SbBottomFixedButtons from '@/components/SbBottomFixedButtons';
import SbCard from '@/components/SbCard.vue';
import SbCardsList from '@/components/SbCardsList';
import SbPageHeader from '@/components/SbPageHeader';
import { quickStartInfoMixin } from '@/mixins/quickStartInfoMixin';
import gql from 'graphql-tag';
import SbDivider from '@/components/SbDivider.vue';

export default {
  components: {
    SbPageHeader,
    SbCardsList,
    SbBottomFixedButtons,
    SbCard,
    SbDivider,
  },

  mixins: [quickStartInfoMixin],

  data() {
    return { selectedCard: undefined, toggle: true };
  },

  methods: {
    async handleStart() {
      this.$refs.cardsList.startExercise(this.selectedCard);
    },
  },

  apollo: {
    me: {
      query: gql`
        query Me {
          me {
            id
            fullName
          }
        }
      `,
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_dashboard_student_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_title {
    margin-bottom: 0.8rem;
  }

  &_description {
    color: $brand-gray;
  }
}

.container {
  /* @include max-content-width; */
  max-width: 100%;
}

.bottom-bar {
  padding: 0 40px;
  @media screen and (min-width: 1200px) {
    padding: 0 80px;
  }
}

.flex {
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  margin-top: 70px;

  .left {
    @include max-content-width;

    .flex_left_divider {
      margin-top: 0px;
    }
  }
}
</style>
