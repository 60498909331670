<template>
  <tooltip
    class="sb_info-tooltip"
    :content="content"
    :multiple-lines="true"
    :max-width="400"
    placement="right-end"
    white-space="nowrap"
    style="white-space: nowrap"
  >
    <div class="sb_info-tooltip_content">
      <slot />
      <sb-icon
        class="sb_info-tooltip_content-icon"
        icon-id="icon_info"
        :color="color"
      />
    </div>
  </tooltip>
</template>

<script>
export default {
  name: 'SbTooltipInfo',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data: () => {
    return {
      color: '#d2d2d2',
    };
  },
};
</script>

<style lang="scss">
.sb_info-tooltip_content {
  display: flex;
}

.sb_info-tooltip_content-icon {
  margin-left: 8px;
  cursor: pointer;
}

.ivu-tooltip-inner-with-width {
  // Used to override wrapping from the tooltip component (iview)
  white-space: break-spaces;
  word-break: break-word;
}
</style>
