<template>
  <div
    :key="buster"
  >
    <dashboard-admin
      v-if="adminContext"
    />
    <dashboard-student
      v-else-if="$user.role === 'STUDENT'"
    />
    <dashboard-coach
      v-else-if="$user.role === 'COACH'"
    />
    <dashboard-students-report
      v-else-if="$user.resourceType === 'SCHOOL'"
    />
    <dashboard-school-district
      v-else-if="$user.resourceType === 'SCHOOL_DISTRICT'"
    />
    <dashboard v-else />
  </div>
</template>

<script>
import Dashboard from '@/views/Default/Dashboard.vue';
import DashboardAdmin from '@/views/Admin/Dashboard.vue';
import DashboardCoach from '@/views/Coach/Dashboard.vue';
import DashboardStudentsReport from '@/views/Default/DashboardStudentsReport.vue';
import DashboardSchoolDistrict from '@/views/Default/DashboardSchoolDistrict.vue';
import DashboardStudent from '@/views/Default/DashboardStudent.vue';

// import QueryMe from '@/graphql/queries/Me.gql';

export default {
  components: {
    Dashboard,
    DashboardCoach,
    DashboardStudent,
    DashboardAdmin,
    DashboardStudentsReport,
    DashboardSchoolDistrict
  },
  data() {
    return {
      adminContext: null,
      buster: 1
    };
  },
  // apollo: {
  //   me: {
  //     query: QueryMe
  //   }
  // },
  watch: {
    $route: {
      handler() {
        this.adminContext = this.$user.context.isAdmin;
        this.buster = Math.random();
      },
      immediate: true
    }
  }
};
</script>

<style>

</style>
