<template>
  <div
    class="sb_insight-list"
    :class="'s_vibe-' + vibe "
  >
    <h2>
      {{ title || ' ' }}
    </h2>
    <div
      class="sb_insight-list_top"
    >
      <div
        class="sb_insight-list_top_left"
      >
        <div
          class="sb_insight-list_subtitle"
        >
          {{ subtitle }}
        </div>
        <div
          class="sb_insight-list_description"
        >
          {{ description }}
        </div>
      </div>
      <div
        class="sb_insight-list_top_right"
      >
        <div
          v-if="data && data.length && showCount"
          class="sb_insight-list_amount"
        >
          ({{ data && data.length }})
        </div>
      </div>
    </div>

    <div
      class="sb_insight-list_header"
      :class="{'v_has-numbering' : showNumbering }"
    >
      <div
        v-if="showNumbering"
        class="sb_insight-list_header_item"
      >
        #
      </div>
      <div
        v-for="(item, index) in mappedHeaders"
        :key="'header-item_' + index"
        class="sb_insight-list_header_item"
      >
        {{ item }}
      </div>
    </div>

    <div
      class="sb_insight-list_items"
      :class="{'s_has-overflow': data && data.length > 5}"
    >
      <div
        v-if="!data || !data.length"
        style="padding: 24px; text-align: center;"
      >
        Geen resultaten
      </div>
      <div
        v-if="data && data.length"
        class="sb_insight-list_items_inner"
      >
        <div
          v-for="(item, index) in data"
          :key="'item_' + index"
          class="sb_insight-list_item"
          :class="{'v_has-numbering' : showNumbering }"
        >
          <div
            v-if="showNumbering"
            class="sb_insight-list_item_no"
          >
            {{ index + 1 }}
          </div>
          <template
            v-for="(header, i) in headers"
          >
            <button
              v-if="clickTriggers[header]"
              :key="item[headers[i]]"
              @click="clickTriggers[header](item)"
            >
              {{ item[headers[i]] }}
            </button>
            <div
              v-else
              :key="item[headers[i]]"
            >
              {{ item[headers[i]] }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbInsightList',
  props: {
    /**
    * The title, placed in the H2
    */
    title: {
      type: String,
      default: ''
    },
    /**
    * The subtitle
    */
    subtitle: {
      type: String,
      default: ''
    },
    /**
    * The decription
    */
    description: {
      type: String,
      default: ''
    },
    /**
     * The vibe of the list. Displays text in a color. Possible values: 'neutral', 'positive, 'negative'
     */
    vibe: {
      type: String,
      default: 'neutral',
      validator: value => [
        'neutral',
        'positive',
        'negative'
      ].includes(value)
    },
    /**
    * The data containing the items listed
    */
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
    * The suffix appended to the value
    */
    valueSuffix: {
      type: String,
      default: ''
    },
    /**
    * Whether to show the numbering or not
    */
    showNumbering: {
      type: Boolean,
      default: false
    },
    /**
    * Whether to show the secondary person or not. This could be the coach of the student, for instance
    */
    showSecondary: {
      type: Boolean,
      default: false
    },
    /**
    * Add a header the list. The order of the items should be equal in size and layout to the data given. Start with a number header if present.
    */
    headerMap: {
      type: Object,
      default() {
        return {};
      }
    },
    clickTriggers: {
      type: Object,
      default() {
        return {};
      }
    },
    showCount: {
      type: Boolean,
      default: true
    }

  },
  computed: {
    headers() {
      return Object.keys(this.headerMap);
    },
    mappedHeaders() {
      return Object.values(this.headerMap);
    }
  }
};
</script>

<style lang="scss" scoped>
  .sb_insight-list {

    h2 {
      margin: 0 0 25px 0;
    }

    &_top {
      display: flex;
      justify-content: space-between;
      min-height: 52px;
      &_left {

      }
      &-right {

      }
    }

    //
    //  SUBTITLE
    //
    &_subtitle {
      font-weight: 600;
      font-size: 20px;
    }

    //
    //  DESCRIPTON
    //
    &_description {
      color: $brand-gray;
      font-size: 15px;
      min-height: 32px;
    }

    //
    //  AMOUNT
    //
    &_amount {
      color: $brand-gray;
      font-size: 20px;
    }

    //
    //  HEADER
    //
    &_header {
      display: flex;
      padding: 10px 30px 0 16px;
      margin: 0 0 5px 0;
      min-height: 30px;

      &.v_has-numbering {
        .sb_insight-list_header_item {
          &:nth-child(1){
            flex: 0 0 20px;
          }
          &:nth-child(2) {
            flex: 1 1 auto;
          }
          &:nth-child(3) {
            flex: 0 0 140px;
          }
        }
      }
      &:not(.v_has-numbering) {
        .sb_insight-list_header_item {
          &:nth-child(1){
            flex: 1 1 auto;
          }
          &:nth-child(2) {
            flex: 0 0 140px;
          }
          &:nth-child(3) {
            flex: 0 0 100px;
          }
        }
      }

      &_item {
        padding: 0 10px 0 0;

        &:last-child {
          padding: 0 0 0 10px;
          flex: 0 0 100px;
          text-align: right;
        }
      }


    }


    //
    //  ITEMS
    //
    &_items{
      position: relative;
      border-top: solid 1px $brand-light-gray;
      border-bottom: solid 1px $brand-light-gray;
      margin: 0;

      &.s_has-overflow {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 30px;
          background: linear-gradient(0deg, rgba($brand-white, 1), rgba($brand-white, 0));
          pointer-events: none;
        }
        .sb_insight-list_items_inner {
           padding: 2px 0 30px 0;
        }
      }

      &_inner {
        padding: 2px 0 0 0;
        height: 224px;
        overflow-y: scroll;
      }
    }

    &_item {
      background: $brand-lightest-gray;
      border-radius: 8px;
      padding: 8px 16px;
      margin: 0 0 4px 0;
      display: flex;

      button {

        font-weight: 600;
        color: $brand-primary;
        transition: color .2s ease;

        &:hover {
          color: $brand-primary-darkest;
        }
      }


      > div,
      > button  {
        padding: 0 10px 0 0;
        flex: 1 1 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:last-child {
          padding: 0;
          text-align: right;
        }
      }
      &_no {
        color: $brand-gray;
        flex: 0 0 20px !important;
      }
      > * {
        &:nth-child(1) {

        }
        &:nth-child(2) {
          flex: 0 0 140px;
        }
        &:nth-child(3) {
          flex: 0 0 100px;
        }
      }

      &.v_has-numbering {
        > * {
          &:nth-child(1) {

          }
          &:nth-child(2) {
            flex: 1 1 auto;
          }
          &:nth-child(3) {
            flex: 0 0 140px;
          }
          &:nth-child(4) {
            flex: 0 0 100px;
          }
        }
      }

      // &_name {
      //   flex: 1 1 auto;
      //   white-space: nowrap;
      // }
      // &_secondary-name {
      //   flex: 0 0 140px;
      //   white-space: nowrap;
      //   text-overflow: ellipsis;
      //   overflow: hidden;
      // }
      // &_value {
      //   flex: 0 0 100px;
      // }


    }

    //
    //  VIBE
    //
    &.s_vibe-neutral {

    }
    &.s_vibe-positive {
      .sb_insight-list_subtitle {
        color: $brand-vibe-green;
      }
    }
    &.s_vibe-negative {
      .sb_insight-list_subtitle {
        color: $brand-vibe-red;
      }
    }

  }
</style>
