import { TableConfig } from '@/components/SbTable2/TableConfig';
import { readingLevelMixin } from '@/lib/reading-level';
import { vmConfirm } from '@/lib/vm-confirm';
import gql from 'graphql-tag';
import { nonReactiveMembersMixin } from './nonReactiveMembersMixin';
import { sleep } from '@/lib/sleep';

/**
 *
 * @description Always returns true because this method is only called if the coach can see
 * the user. And if so the coach can always see and manage the track. Even if somehow the user
 * tries to bypass the frontend and call the API directly the backend still validates the permission.
 * @returns
 */
function userWithAccess(user) {
  // Legacy: return user.role === 'TRACK_CREATOR' || user.role === 'TRACK_COACH';
  return true;
}

export function trackManagerMixin(options = () => undefined) {
  return Object.freeze({
    mixins: [
      readingLevelMixin,
      nonReactiveMembersMixin((self) => ({
        tracksTableConfig: new TableConfig({
          rowActions: [
            (row) =>
              row.users?.find((user) => userWithAccess(user)) && !row.finishedAt
                ? row.active
                  ? ['deactivate', 'Deactiveer']
                  : ['activate', 'Activeer']
                : [],
            (row) =>
              row.users?.find((user) => userWithAccess(user))
                ? ['delete', 'Verwijder']
                : [],
            (row) =>
              row.users?.find((user) => userWithAccess(user)) && !row.finishedAt
                ? ['finish', 'Sluiten']
                : [],
          ],
          columns: [
            {
              key: 'readingLevel',
              header: 'Niveau',
              sortable: true,
              data: (row) =>
                row.readingLevels.reduce(
                  (acc, cur, index) =>
                    acc +
                    cur +
                    (index < row.readingLevels.length - 1 ? ', ' : ''),
                  '',
                ) || 'N.t.b.',
            },
            {
              key: 'active',
              header: 'Status',
              sortable: true,
              data: (row) => (row.active ? 'Actief' : 'Inactief'),
            },
            {
              key: 'users',
              header: 'Coach',
              data: function (row) {
                const user = row.users.find((u) => u.role === 'TRACK_STUDENT');
                if (user) {
                  const coachEdges =
                    user.user.followingGroups?.flatMap(
                      (group) => group.coaches,
                    ) ?? [];

                  const coaches = coachEdges[0]?.edges;
                  const concatenatedNames = coaches
                    ?.map((edge) => edge?.node?.fullName)
                    .join(', ');

                  const maxCharacterLimit = 25;
                  const truncatedNames =
                    concatenatedNames.length > maxCharacterLimit
                      ? concatenatedNames.substring(0, maxCharacterLimit) +
                        '...'
                      : concatenatedNames;

                  return truncatedNames;
                }

                return '-';
              },
            },
            {
              key: 'startDate',
              header: 'Startdatum',
              sortable: true,
              data: (row) =>
                row.startDate
                  ? new Date(row.startDate).toLocaleDateString()
                  : '-',
            },
            {
              key: 'endDate',
              header: 'Einddatum',
              sortable: true,
              data: (row) =>
                row.endDate ? new Date(row.endDate).toLocaleDateString() : '-',
            },
            {
              key: 'finishedAt',
              header: 'Sluitdatum',
              sortable: true,
              data: (row) =>
                row.finishedAt
                  ? new Date(row.finishedAt).toLocaleDateString()
                  : '-',
            },
            {
              key: 'createdAt',
              header: 'Aanmaakdatum',
              sortable: true,
              sort: 'descending',
              data: (row) => new Date(row.createdAt).toLocaleDateString(),
            },
          ],
        }),
      })),
    ],

    computed: {
      studentsWithoutActiveTrack() {
        return this.trackManagerData?.studentsWithoutActiveTrack ?? [];
      },

      studentActiveTrackLookup() {
        return this.trackManagerData?.studentActiveTrackLookup ?? {};
      },

      studentTracksLookup() {
        return this.trackManagerData?.studentTracksLookup ?? {};
      },

      studentLookup() {
        return this.trackManagerData?.studentLookup ?? {};
      },

      isOwnTrack() {
        return (track) =>
          !!track.users?.find(
            (user) =>
              user.role === 'TRACK_CREATOR' && user.user.id === this.$user.id,
          );
      },
    },

    watch: {
      trackManagerData: {
        deep: true,
        handler(value) {
          options(this)?.onDataChange?.(value);
        },
      },
    },

    created() {
      if (
        this.$user.role !== 'COACH' &&
        process.env.NODE_ENV === 'development'
      ) {
        throw new Error('Track manager mixin is only available to Coaches');
      }
    },

    methods: {
      refresh() {
        this.$apollo.queries.trackManagerData.refetch();
        options?.(this).onTrackManagerDataRefresh?.();
      },

      async deleteTrack(id, callback) {
        const self = this;
        this.$Modal.confirm({
          title: 'Weet je het zeker?',
          content:
            'Je staat op het punt een traject te verwijderen. Weet je zeker dat je door wil gaan?',
          loading: true,
          async onOk() {
            try {
              const result = await self.$apollo.mutate({
                mutation: gql`
                  mutation deleteTrack($id: ID!) {
                    deleteTrack(id: $id)
                  }
                `,
                variables: { id },
              });

              if (result.errors) {
                throw new Error(errors.map((e) => e.message).join(', '));
              }

              this.remove();
              if (callback) {
                callback();
              }
              self.$Message.success('Traject verwijderd');
              self.refresh();
            } catch (error) {
              console.error(error);
              this.remove();

              setTimeout(() => {
                self.$Modal.error({
                  title: 'Er ging iets mis',
                  content: 'Probeer het later nog eens.',
                });
              }, 300);
            }
          },
        });
      },

      /**
       * Searches a users currently active track, and deactivates it if found
       * @param {String} userId
       * @returns
       */
      async deactivateTrackByUserId(userId) {
        const user = this.trackManagerData?.studentLookup[userId];
        const activeTrackId = this.trackManagerData?.studentActiveTrackLookup[
          userId
        ];

        if (!user) {
          throw new Error('User not found.');
        }

        if (!activeTrackId) {
          console.error('No active track');
          return;
        }

        this.deactivateTrack(activeTrackId);
      },

      async deactivateTrack(id, notify = false) {
        try {
          await this.updateTrack(id, { active: false });
          if (notify) this.$Message.success('Traject gedeactiveerd!');
        } catch (error) {
          console.error(error);
          this.$Modal.error({
            title: 'Er ging iets mis...',
            content:
              'Het traject kon niet gedeactiveerd worden. Probeer het later nog eens.',
          });
        }
      },

      async activateTrack(
        trackId,
        studentId = this.studentId,
        doSkipActivateConfirm = false,
      ) {
        let doContinue;
        const popupSleepTime = 300;
        const fetchStart = Date.now();

        try {
          const findActiveTrack = await this.$apollo.query({
            fetchPolicy: 'network-only',
            variables: {
              tracksFilter: {
                active: true,
                users: [
                  { userId: { equals: studentId }, role: 'TRACK_STUDENT' },
                  { userId: { equals: this.$user.id }, role: 'TRACK_CREATOR' },
                  { userId: { equals: this.$user.id }, role: 'TRACK_COACH' },
                ],
              },
            },
            query: gql`
              query TrackManagerMixin_FindActiveTrack(
                $tracksFilter: TracksFilter
              ) {
                tracks(filter: $tracksFilter) {
                  edges {
                    node {
                      id
                      active
                      users {
                        userId
                        role
                      }
                    }
                  }
                }
              }
            `,
          });

          if (findActiveTrack.errors) {
            throw new Error('Find active track failed');
          }

          const activeTrack = findActiveTrack.data?.tracks.edges[0]?.node;

          if (activeTrack) {
            if (doSkipActivateConfirm) {
              doContinue = true;
            } else {
              await sleep(
                Math.max(0, popupSleepTime - (Date.now() - fetchStart)),
              );
              doContinue = await vmConfirm.call(
                this,
                'Reeds actief traject',
                'Er kan slechts één traject actief zijn. Wil je het huidige actieve traject deactiveren?',
              );
            }

            if (!doContinue) return;

            const deactivated = await this.updateTrack(activeTrack.id, {
              active: false,
            });

            if (deactivated.errors) {
              throw new Error('Could not deactivate current track');
            }
          }

          const activated = await this.updateTrack(trackId, {
            active: true,
          });

          if (activated.errors) {
            throw new Error('Could not update track');
          }

          this.$Message.success('Nieuw traject geactiveerd');
        } catch (error) {
          console.error(error);
          this.$showGenericError();
        }
      },

      // async activateTrack(id, prompt = true) {
      //   const trackToActivate = this.trackManagerData?.trackLookup[id];
      //   const studentId = this.trackManagerData?.trackStudentLookup[id];
      //   const currentActiveTrack = this.trackManagerData?.studentTracksLookup[
      //     studentId
      //   ]?.find((track) => track.active);

      //   if (!trackToActivate) {
      //     console.error('Track not found', id);
      //     return;
      //   }

      //   // if track is already active
      //   if (trackToActivate.active) return;

      //   if (currentActiveTrack) {
      //     const doContinue = prompt
      //       ? await vmConfirm.call(
      //         this,
      //         'Reeds actief traject',
      //         'Er kan slechts één traject actief zijn. Wil je het huidige actieve traject deactiveren?',
      //       )
      //       : true;

      //     if (doContinue) {
      //       await this.deactivateTrack(currentActiveTrack.id);
      //     } else return;
      //   }

      //   try {
      //     await this.updateTrack(id, { active: true });
      //     this.$Message.success('Traject geactiveerd!');
      //   } catch (error) {
      //     console.error(error);
      //     this.$Modal.error({
      //       title: 'Er ging iets mis...',
      //       content:
      //         'Het traject kon niet geactiveerd worden. Probeer het later nog eens.',
      //     });
      //   }
      // },

      async finishTrack(id, notify = false) {
        const doContinue = prompt
          ? await vmConfirm.call(
              this,
              'Weet je het zeker?',
              'Hiermee wordt een traject definitief afgesloten. Weet je het zeker?',
            )
          : true;

        if (doContinue) {
          await this.$apollo.mutate({
            mutation: gql`
              mutation finishTrack($id: ID!) {
                finishTrack(id: $id) {
                  id
                  finishedAt
                }
              }
            `,
            variables: {
              id,
            },
          });
          if (notify) this.$Message.success('Traject gesloten!');
          return;
        }
      },

      async updateTrack(id, data) {
        return await this.$apollo.mutate({
          mutation: gql`
            mutation updateTrack($input: UpdateTrackInput!) {
              updateTrack(input: $input) {
                id
                active
              }
            }
          `,
          variables: {
            input: { id, data },
          },
        });
      },

      async createTrack(
        studentId,
        { startDate, endDate, isReadingComprehension },
      ) {
        try {
          const result = await this.$apollo.mutate({
            mutation: gql`
              mutation CreateTrack($input: CreateTrackInput!) {
                createTrack(input: $input) {
                  id
                }
              }
            `,
            variables: {
              input: {
                active: false,
                problems: [],
                studentId,
                startDate: startDate.toISOString(),
                endDate: endDate ? endDate.toISOString() : undefined,
                isReadingComprehension,
              },
            },
          });

          return result.data;
        } catch (error) {
          console.error(error);
          if (error.message.includes('has an overlap')) {
            this.$Modal.warning({
              title: 'Foutieve invoer',
              content:
                'De looptijd van dit traject overlapt de looptijd van een ander traject.',
            });
          } else this.$showGenericError();
        }
      },

      getTrackTableQueryOptions(studentId) {
        trackTableQueryOptionsStudentId = studentId;
        return TRACK_TABLE_QUERY_OPTIONS;
      },
    },

    apollo: {
      trackManagerData: {
        fetchPolicy: 'cache-and-network',

        skip() {
          return !options?.(this)?.useTrackManagerData;
        },

        query: gql`
          query TrackManagerData(
            $studentFilter: UsersFilter!
            $studentTrackFilter: TracksFilter!
          ) {
            me {
              id
              coachingStudents(filter: $studentFilter) {
                edges {
                  node {
                    id
                    fullName
                    classYear
                    birthDate
                    email
                    tracks(filter: $studentTrackFilter) {
                      edges {
                        node {
                          id
                          active
                          readingLevels
                          createdAt
                          users {
                            userId
                            role
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,

        variables() {
          return {
            studentFilter: {
              id: {
                equals: this.studentId,
              },
            },
            studentTrackFilter: {
              ...options?.(this)?.trackFilter,
            },
          };
        },

        update(data) {
          const map = data.me.coachingStudents.edges.reduce(
            (acc, { node: student }) => {
              const tracks = student.tracks.edges
                .map(({ node }) => node)
                .filter(
                  (track) =>
                    !!track.users.find(
                      (user) =>
                        user.role === 'TRACK_CREATOR' &&
                        user.userId === this.$user.id,
                    ),
                );

              const activeTracks = tracks.filter((track) => track.active);

              acc.students.push(student);
              acc.studentLookup[student.id] = student;
              acc.studentTracksLookup[student.id] = tracks.slice();
              acc.studentActiveTrackLookup[student.id] = tracks.find(
                (track) => track.active,
              )?.id;

              tracks.forEach((track) => {
                acc.trackLookup[track.id] = track;
                acc.trackStudentLookup[track.id] = student.id;
              });

              if (!activeTracks.length) {
                acc.studentsWithoutActiveTrack.push(student);
              } else {
                acc.studentsWithActiveTrack.push(student);
              }

              return acc;
            },

            {
              students: [],
              studentLookup: {},
              studentActiveTrackLookup: {},
              studentTracksLookup: {},
              trackStudentLookup: {},
              trackLookup: {},
              studentsWithoutActiveTrack: [],
              studentsWithActiveTrack: [],
            },
          );

          return map;
        },
      },
    },
  });
}

let trackTableQueryOptionsStudentId;
const TRACK_TABLE_QUERY_OPTIONS = ({
  first,
  last,
  after,
  before,
  filter,
  filters,
  orderBy,
  pagination,
}) => {
  return {
    query: gql`
      query TrackManagerMixin_Tracks(
        $first: Int
        $after: String
        $last: Int
        $before: String
        $filter: TracksFilter
        $orderBy: TracksOrderBy
      ) {
        tracks(
          first: $first
          after: $after
          last: $last
          before: $before
          filter: $filter
          orderBy: $orderBy
        ) {
          ${pagination}
          edges {
            node {
              id
              createdAt
              startDate
              endDate
              finishedAt
              readingLevels
              active
              users {
                userId
                role
                user {
                  id
                  fullName
                  followingGroups {
                    coaches {
                      edges {
                        node {
                          fullName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,

    variables: {
      first,
      last,
      after,
      before,
      filter: {
        ...filter,
        users: [{ userId: { equals: trackTableQueryOptionsStudentId } }],
      },
      orderBy,
    },
  };
};
