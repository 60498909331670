<template>
  <div class="sb_card" :class="{ v_clickable: !!$listeners.click }">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.sb_card {
  width: 300px;
  padding: 1rem;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid $brand-light-gray;
  box-sizing: border-box;
  box-shadow: 0 0 8px rgba($brand-primary-darkest, 0.1);
  transition: 0.3s background-color, 0.3s border-color;

  &.v_clickable {
    cursor: pointer;
    &:hover {
      border-color: $brand-primary;
      background: $brand-primary-lightest;
      transition-duration: 0s;
    }
  }
}
</style>
