<template>
  <div class="container">
    <sb-page-header>
      <template v-slot:left>
        <h1>Dashboard</h1>
      </template>
      <template v-slot:right> </template>
    </sb-page-header>

    <h2>Aantal gebruikers</h2>

    <sb-spacer height="20" />

    <div class="active-users-grid">
      <sb-active-users-amount
        emoji="🧑‍🤝‍🧑"
        :amount="get(dashboardData, 'accumulatedData', 'totalActiveUsers')"
      />

      <sb-active-users-amount
        emoji="🏫"
        :amount="get(dashboardData, 'accumulatedData', 'totalActiveSchools')"
        label="scholen"
      />

      <sb-active-users-amount
        emoji="📎"
        :amount="
          get(dashboardData, 'accumulatedData', 'totalActiveSchoolDistricts')
        "
        label="districten"
      />
    </div>

    <sb-spacer height="40" />

    <h3
      :class="{
        warning: !!get(dashboardData, 'exceededOrganisations', 'length'),
      }"
    >
      Scholen en districten met te veel actieve gebruikers:
      {{ get(dashboardData, 'exceededOrganisations', 'length') }}
    </h3>

    <template v-if="get(dashboardData, 'exceededOrganisations', 'length')">
      <sb-spacer height="20" />

      <sb-table-2
        :with-navigation="false"
        :config="tableConfig"
        :data="get(dashboardData, 'exceededOrganisations')"
        @cell-click-name="goToOrganisation"
      />
    </template>

    <div
      v-if="
        !$apollo.queries.dashboardData.loading &&
        !get(dashboardData, 'exceededOrganisations', 'length')
      "
      style="padding: 10px"
    >
      ✅ Er zijn geen scholen met te veel actieve gebruikers
    </div>
    <sb-spacer height="100" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbActiveUsersAmount from '@/components/SbActiveUsersAmount';
import SbTable2 from '@/components/SbTable2/SbTable2';
import { TableConfig } from '@/components/SbTable2/TableConfig';
import gql from 'graphql-tag';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';

export default {
  components: {
    SbPageHeader,
    SbTable2,
    SbActiveUsersAmount,
  },

  mixins: [
    nonReactiveMembersMixin(() => ({
      tableConfig: new TableConfig({
        columns: [
          {
            hide: false,
            key: 'name',
            header: 'Naam',
            width: 250,
            sortable: false,
            action: true,
          },
          {
            key: 'type',
            header: 'Schooltype',
            data: (row) =>
              ({ SCHOOL: 'School', SCHOOL_DISTRICT: 'Schooldistrict' }[
                row.organisationType
              ]),
          },
          {
            key: 'activeUsers',
            header: 'Actieve gebruikers',
            data: (row) => `${row.takenSeats}/${row.seats}`,
          },
        ],
      }),
    })),
  ],

  methods: {
    goToOrganisation(e) {
      this.$router.push({
        name: 'ResourceManagementSchoolsSchool',
        params: { organisationId: e.id },
      });
    },
  },

  apollo: {
    dashboardData: {
      update: (data) => data,

      query: gql`
        query Admin_Dashboard {
          accumulatedData {
            totalActiveSchools
            totalActiveSchoolDistricts
            totalActiveUsers
          }
          exceededOrganisations {
            id
            organisationType
            name
            seats
            takenSeats
          }
        }
      `,
    },
  },
};
</script>

<style lang="scss">
.container {
  @include max-content-width;
}

.active-users-grid {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 1rem;
}

.warning {
  color: $brand-red;
}
</style>
