<template>
  <div>
    <sb-gql-table ref="table" :config="sessionsTableConfig" :query-options="queryOptions" :data-path="tableDataPath"
      :custom-converter="customConverter" @cell-click-username="viewCoach">
      <template slot="column-username" slot-scope="{ content }">
        <span class="username">{{ content }}</span>
      </template>
    </sb-gql-table>
  </div>
</template>

<script>
import CoachActivityTable from '@/components/SbCoachActivityTable/CoachActivityTable.gql';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from '@/components/SbTable2/TableConfig';
import { coachTypeMixin } from '@/lib/coach-type';
import SbGqlTable from '@/components/SbTable2/SbGqlTable.vue';

export default {
  components: {
    SbGqlTable,
  },

  mixins: [
    coachTypeMixin,
    nonReactiveMembersMixin((self) => {
      const sessionsTableConfig = new TableConfig({
        columns: [
          {
            key: 'username',
            header: 'Gebruikersnaam',
            width: 200,
            action: true,
            data: (row) =>
              row.coachUsername
          },
          {
            key: 'fullname',
            header: 'Begeleider',
            width: 200,
            data: (row) =>
              row.coachName
          },
          {
            key: 'updatedAt',
            header: 'Datum',
            data: (row) => {
              if (!row.updatedAt) return '';
              const date = new Date(row.updatedAt);
              return [
                date.toLocaleDateString(),
                date.toLocaleTimeString(),
              ].join(', ');
            },
            sortable: true,
            sort: 'ascending',
          },
          {
            key: 'trackStudent',
            header: 'Naam',
            width: 200,
            data: (row) => row.studentName
          },
          {
            key: 'trackStudent',
            header: 'Leerling',
            width: 200,
            data: (row) => row.studentUsername
          },
          {
            key: 'track',
            header: 'Leesniveau',
            data: (row) => row.readingLevel
          },
          {
            key: 'problemFocus',
            header: 'Problematiek',
            data: (row) => row.problemFocus,
          },
          {
            key: 'cards',
            header: 'Kaarten',
            width: 300,
            data: (row) => row.cardTitle
          },

          {
            key: 'status',
            header: 'Status',
            data: (row) => row.status,
          },
        ],
      });
      return { sessionsTableConfig };
    }),
  ],

  props: {
    organisationId: {
      type: String,
      default: null,
    },
    courseGroupId: {
      type: String,
      default: null,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tableDataPath() {
      return 'getLatestSessionsPerCoach';
    },
  },

  methods: {
    getStatus(session, finishedAt) {
      if (!session) return '-'; // Means no latest session is available.
      return finishedAt === '-' ? 'Bezig' : 'Voltooid';
    },

    customConverter(data) {
      if (!data || !data?.edges) return [];
      const nodes = data?.edges?.map((edge) => edge.node);
      const placeholder = '-';
      const result = nodes.map(node => {
        const coachUsername = node.username ?? placeholder;
        const lastSession = node.lastSession;
        const createdAt = lastSession?.createdAt ?? '-';
        const updatedAt = lastSession?.updatedAt;
        const finishedAt = lastSession?.finishedAt ?? '-';
        const student = lastSession?.track?.users?.find((u) => u.role === 'TRACK_STUDENT')?.user;
        const studentUsername = student?.username ?? '-';
        const studentName = student?.fullName ?? '-';
        const problemFocus = lastSession !== null ? (lastSession?.problemFocus?.title ?? '-') : '-';
        const readingLevel = lastSession !== null ? (lastSession?.cards?.[0]?.card?.level ?? '-') : '-';
        const cardTitle = lastSession !== null ? (lastSession?.cards?.[0]?.card?.title ?? '-') : '-';
        const status = this.getStatus(lastSession, finishedAt);

        return {
          id: node.id,
          coachUsername,
          coachName: node.fullName,
          studentUsername,
          studentName,
          studentUsername,
          problemFocus,
          readingLevel,
          cardTitle,
          createdAt,
          updatedAt,
          finishedAt,
          status
        }
      }) ?? [];

      return result;
    },
    queryOptions({ first, last, after, before, filter, orderBy }) {

      const query = CoachActivityTable;
      const variables = {
        first,
        last,
        after,
        before,
        orderBy,
        filter: {
          ...filter,
        },
      };

      return { query, variables };
    },

    viewCoach(coach) {
      const id = coach.id;
      const resourceId = this.$user.context.resourceId;
      if (!id) return;

      this.$router.push({
        name: 'ResourceManagementCoachesCoach',
        params: {
          resourceType: 'school',
          resourceId: resourceId,
          coachId: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.username {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
}
</style>
