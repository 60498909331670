<template>
  <i-form v-model="value" :label-width="160">
    <p>Kies een start- en optionele einddatum voor dit traject.</p>

    <sb-spacer height="20" />

    <form-item label="Startdatum" prop="startDate">
      <date-picker
        v-model="value.startDate"
        format="dd-MM-yyyy"
        placeholder="dd-mm-jjjj"
        :options="{ disabledDate: getDisabledStartDate }"
      />
    </form-item>

    <form-item label="Einddatum (optioneel)" prop="endDate">
      <date-picker
        v-model="value.endDate"
        format="dd-MM-yyyy"
        placeholder="dd-mm-jjjj"
        :options="{ disabledDate: getDisabledEndDate }"
      />
    </form-item>

    <form-item v-if="readingComprehension" label="Begrijpend lezen traject">
      <sb-info>
        Bij begrijpend lezen trajecten worden alleen kaarten getoond met opgaven
        voor begrijpend lezen.
      </sb-info>
      <i-switch v-model="value.isReadingComprehension">
        <label slot="open">Ja</label>
        <label slot="close">Nee</label>
      </i-switch>
    </form-item>
  </i-form>
</template>

<script>
import { platformSettingsMixin } from '@/mixins/platformSettingsMixin';
import SbInfo from './SbInfo.vue';
export default {
  name: 'SbCreateTrackForm',
  components: { SbInfo },

  mixins: [platformSettingsMixin],

  props: {
    value: {
      type: Object,
      required: true,
      validator: (value) =>
        Object.keys(value).length >= 2 &&
        'startDate' in value &&
        'endDate' in value,
      default: () => ({
        startDate: new Date(),
        endDate: undefined,
        isReadingComprehension: false,
      }),
    },
  },

  computed: {
    readingComprehension() {
      return !!this?.platformSettings?.readingComprehension;
    },
  },

  methods: {
    getDisabledStartDate(current) {
      const today = new Date();
      return new Date(today.toDateString()) > new Date(current.toDateString());
    },
    getDisabledEndDate(current) {
      const today = new Date();
      return this.value.startDate
        ? new Date(this.value.startDate.toDateString()) >=
            new Date(current.toDateString())
        : new Date(today.toDateString()) > new Date(current.toDateString());
    },
  },
};
</script>
