<template>
  <div>
    <sb-table-2
      ref="table"
      :loading="loading"
      v-bind="forwardProps"
      :data="tableData"
      :has-next-page="get(data, 'pageInfo', 'hasNextPage')"
      :has-previous-page="get(data, 'pageInfo', 'hasPreviousPage')"
      :total-count="totalCount"
      :singular-sorting="true"
      v-on="$listeners"
      @filter="handleFilter"
      @mount="handleTableMount"
      @navigate="handleNavigate"
      @sort="handleSort"
      @next="handleNext"
      @previous="handlePrevious"
    >
      <!-- eslint-disable-next-line -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </sb-table-2>
  </div>
</template>

<script>
import { objectOmit } from '@/lib/object-omit';
import SbTable2 from './SbTable2.vue';

const REQUIRED_PAGINATION_FIELDS =
  'totalCount pageInfo { hasNextPage hasPreviousPage startCursor endCursor }';

export default {
  name: 'SbGqlTable',
  components: { SbTable2 },

  props: {
    queryOptions: { type: Function, required: true },
    dataPath: { type: String, required: false, default: '' },
    customConverter: { type: Function, required: false, default: undefined },
    queryName: { type: String, required: false, default: 'data' },
  },

  data() {
    return {
      loading: false,
      sort: {},
      filters: {},
      navigation: {},
      first: undefined,
      last: undefined,
      before: undefined,
      after: undefined,
      data: undefined,
    };
  },

  computed: {
    forwardProps() {
      return objectOmit(this.$attrs);
    },

    totalCount() {
      return this.data?.totalCount ?? 0;
    },

    tableData() {
      if (this.customConverter) {
        return this.customConverter(this.data);
      }
      return this.data?.edges?.map(({ node }) => node) ?? [];
    },

    gqlQueryParams() {
      const filters = Object.entries(this.filters).reduce(
        (acc, [key, value]) =>
          value ? ((acc[key] = _.cloneDeep(value)), acc) : acc,
        {},
      );

      const orderBy = Object.entries(this.sort).reduce(
        (acc, [key, value]) => (
          (acc[key] = this.getGqlOrderByType(value)), acc
        ),
        {},
      );

      return {
        after: this.after,
        before: this.before,
        first: this.first,
        last: this.last,
        filters,
        orderBy,
      };
    },
  },

  created() {
    this.$apollo.addSmartQuery('data', {
      fetchPolicy: 'cache-and-network',
      debounce: 300,
      name: 'adsf',

      watchLoading(loading) {
        this.$emit('loading', loading);
        this.loading = loading;
      },

      query() {
        return this.getDynamicValue(
          this.mergeOptions(this.gqlQueryParams).query,
        );
      },

      variables() {
        return this.getDynamicValue(
          this.mergeOptions(this.gqlQueryParams).variables,
        );
      },

      update(response) {
        const dataPath = this.dataPath.split('.').reverse();
        let path;
        let data;

        while (dataPath.length > 0) {
          path = dataPath.pop();
          data = (data ?? response)[path];
        }

        if (!data?.__typename.startsWith('Paginated')) {
          throw new Error(
            `Response data is missing or not paginated at "${this.dataPath}"`,
          );
        }

        if (data?.pageInfo == undefined) {
          throw new Error('PageInfo is missing in response data.');
        }

        if (data?.totalCount == undefined) {
          throw new Error('TotalCount is missing in response dat a.');
        }

        return data;
      },
    });
  },

  methods: {
    unselectAllRows() {
      this.$refs.table.handleMasterSelect(false);
    },

    selectAllRows() {
      this.$refs.table.handleMasterSelect(true);
    },

    refresh() {
      this.$apollo.queries?.data?.refetch?.();
    },

    getDynamicValue(input) {
      return typeof input === 'function' ? input() : input;
    },

    handleTableMount(table) {
      this.first = table.navigation.pageSize;
    },

    handleFilter(event) {
      this.last = undefined;
      this.before = undefined;
      this.after = undefined;
      this.filters = event;
    },

    handleNavigate(event) {
      this.first = event.pageSize;
      this.last = undefined;
      this.before = undefined;
      this.after = undefined;
    },

    handleSort(event) {
      this.sort = event;
    },

    handleNext() {
      this.before = undefined;
      this.first = this.first || this.last;
      this.last = undefined;
      if (this.data?.pageInfo.hasNextPage) {
        this.after = this.data.pageInfo.endCursor;
      }
    },

    handlePrevious() {
      this.after = undefined;
      this.last = this.first || this.last;
      this.first = undefined;
      if (this.data?.pageInfo.hasPreviousPage) {
        this.before = this.data.pageInfo.startCursor;
      }
    },

    getGqlOrderByType(from) {
      if (from === 'descending') return 'DESC';
      return 'ASC';
    },

    mergeOptions(source) {
      const baseOptions = {
        filter: Object.entries(source.filters)
          .filter(([, value]) => !!value)
          .reduce((acc, [key, value]) => {
            const meta = value.column.meta;
            let Filter;
            let filterData;
            let gqlFilter;

            if (!value.column.meta) return acc;

            Filter = meta.gqlFilter;
            filterData = meta.gqlFilterData?.(value.value) ?? value.value;

            gqlFilter = new Filter(
              meta.gqlFilterOperator ?? value.compareOperator,
              filterData,
              // for future reference, here you can add additional filter configuration, like case sensitivity
              // value.column.filter === 'search'
              //   ? { mode: 'INSENSITIVE' }
              //   : undefined,
            ).create();

            if (gqlFilter) {
              acc[key] = gqlFilter;
            }

            return acc;
          }, {}),
        pagination: REQUIRED_PAGINATION_FIELDS,
      };

      const queryOptions = Object.assign(
        Object.entries(source).reduce(
          (acc, [key, value]) =>
            value == undefined ? acc : ((acc[key] = value), acc),
          {},
        ),
        baseOptions,
      );

      return this.queryOptions(queryOptions);
    },
  },
};
</script>
