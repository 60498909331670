<template>
  <div class="sb_coach-dashboard_container">
    <modal v-model="showCreatorModal" :width="800">
      <h2>Stel een sessie samen</h2>

      <p v-if="hasStudents" class="sb_coach_dashboard_annotation">
        Selecteer één of meerdere leerlingen waarmee je een sessie wil starten.
      </p>

      <sb-call-out v-if="!hasStudents" style="margin-top: 20px">
        <template v-slot:left> 👉 </template>
        <template v-slot:right>
          Er zijn nog geen leerlingen met een traject. Wanneer je leerlingen
          hebt kun je hier een sessie starten. Indien je bent toegewezen aan een
          groep, dan kun je een leerling aanmaken met de onderstaande knop.
        </template>
      </sb-call-out>

      <i-select
        v-if="hasStudents && !$apollo.queries.getStudentsTrackStates.loading"
        v-model="selectedGroupsAndStudents"
        multiple
        filterable
        @on-query-change="studentSearchQuery = $event"
      >
        <template v-for="(group, index) in groups">
          <i-option
            v-if="group.students.length"
            :key="`group-${group.id}`"
            :value="`GROUP_${group.id}`"
            :disabled="!group.hasAvailableStudents"
          >
            <b>{{ group.name || `Groep ${index + 1}` }}</b>
          </i-option>

          <template v-for="student in sortSessionStudents(group.students)">
            <i-option
              v-if="!selectedGroupsAndStudents.includes(`GROUP_${group.id}`)"
              :key="student.id"
              :value="`STUDENT_${student.id}`"
              :disabled="student.noTrack || student.noActiveTrack"
            >
              {{ student.fullName }}
              <span v-if="student.noTrack || student.noActiveTrack">
                - <span class="no-active-track">Nog geen actief traject.</span>
              </span>
            </i-option>
          </template>
        </template>
      </i-select>

      <div slot="footer">
        <i-button
          :disabled="hasStudents && !selectedStudents.length"
          size="large"
          type="primary"
          @click="hasStudents ? createSession() : createStudent()"
        >
          {{ !hasStudents ? 'Leerling aanmaken' : 'Verder' }}
        </i-button>
      </div>
    </modal>

    <modal
      v-if="currentStudent"
      v-model="showTrackModal"
      :width="800"
      :title="`Trajecten van ${get(currentStudent, 'fullName')}`"
      @on-ok="handleTrackSubmit"
    >
      <p class="sb_coach_dashboard_annotation">
        Kies een traject om te activeren.
      </p>
      <i-select v-model="trackId">
        <i-option
          v-for="track in currentStudent.tracks.edges.map((edge) => edge.node)"
          :key="track.id"
          :value="track.id"
        >
          {{ getTrackOptionLabel(track) }}
        </i-option>
      </i-select>
    </modal>

    <modal
      v-model="showCreateTrackModal"
      :width="800"
      title="Kies een leerling"
      @on-ok="handleCreateTrackSubmit"
    >
      <p class="sb_coach_dashboard_annotation">
        Met welke leerling wil je een traject starten?
      </p>
      <i-select v-model="newTrackStudentId" filterable>
        <i-option
          v-for="student in createTrackCandidateStudents"
          :key="student.id"
          :value="student.id"
        >
          {{ student.fullName }}
        </i-option>
      </i-select>
    </modal>

    <modal
      v-model="showNoTrackModal"
      :width="800"
      :title="`Trajecten van ${
        showNoTrackModal && currentStudent && currentStudent.fullName
      }`"
      @on-ok="handleCreateTrack"
    >
      <p>Deze leerling heeft nog geen traject-intake gedaan.</p>
      <sb-create-track-form v-model="createTrackForm" />
    </modal>

    <sb-page-header>
      <template v-slot:left>
        <div class="sb_coach_dashboard_header">
          <h1>Dashboard</h1>
        </div>
      </template>
      <template v-slot:right> </template>
    </sb-page-header>

    <sb-call-out
      v-if="!hasStudents && !$apollo.queries.getStudentsTrackStates.loading"
      style="margin-top: 20px; width: 600px"
    >
      <template v-slot:left> 👉 </template>
      <template v-slot:right>
        Dit is het dashboard. Wanneer je leerlingen hebt kun je hier een
        overzicht van voorbereiding zien en snel trajecten of sessies starten.
      </template>
    </sb-call-out>

    <div class="sb_coach_dashboard_quick-menu">
      <sb-card class="sb_coach_dashboard_quick-menu_card">
        <sb-tooltip-info :content="tooltipSession">
          <h3 class="sb_coach_dashboard_quick-menu_card-title">
            (Groeps)sessie
          </h3>
        </sb-tooltip-info>
        <p class="sb_coach_dashboard_annotation">
          Start een sessie met één of meerdere leerlingen.
        </p>
        <i-button
          id="btn_sessie_starten"
          type="primary"
          size="large"
          @click="showCreatorModal = true"
        >
          Sessie starten
        </i-button>
      </sb-card>

      <sb-card class="sb_coach_dashboard_quick-menu_card">
        <sb-tooltip-info :content="tooltipTrack">
          <h3 class="sb_coach_dashboard_quick-menu_card-title">
            Traject aanmaken
          </h3>
        </sb-tooltip-info>
        <p class="sb_coach_dashboard_annotation">
          Begin een nieuw traject met een leerling.
        </p>
        <i-button
          id="btn_traject_aanmaken"
          type="primary"
          size="large"
          @click="showCreateTrackModal = true"
        >
          Nieuw traject
        </i-button>
      </sb-card>

      <sb-card v-if="quickStartInfo" class="sb_coach_dashboard_quick-menu_card">
        <h3 class="sb_coach_dashboard_quick-menu_card-title">Snelstartgids</h3>
        <p class="sb_coach_dashboard_annotation">
          {{ quickStartInfo.title }}
        </p>
        <i-button
          id="btn_snelstart_gids"
          size="large"
          @click="downloadQuickStart"
        >
          Download
        </i-button>
      </sb-card>
    </div>

    <template>
      <sb-divider title="Voorbereiding nodig" />

      <div class="sb_coach_dashboard_indent">
        <p class="sb_coach_dashboard_annotation">
          Lijst met leerlingen waar nog geen traject voor is gestart, en waar
          nog voorbereidend werk voor gedaan moet worden.
        </p>
        <sb-table-2
          :config="studentsWithoutActiveTrackTableConfig"
          :data="preparationStudents"
          :loading="$apollo.queries.getStudentsTrackStates.loading"
          @cell-click-full-name="handleStudentRowAction"
          @row-action-activate-track="handleStudentRowAction"
        />
      </div>
    </template>

    <template>
      <sb-divider title="Recente activiteit" />

      <div class="sb_coach_dashboard_indent">
        <p class="sb_coach_dashboard_annotation">
          Laatste {{ activeStudentsFetchLimit }} leerlingen die recent actief
          zijn geweest.
        </p>
        <sb-table-2
          :config="recentlyActiveStudentsTableConfig"
          :data="studentsWithRecentActivity || []"
          :loading="$apollo.queries.studentsWithRecentActivity.loading"
          :with-navigation="false"
          @cell-click-full-name="goToStudent"
        />
      </div>
    </template>

    <template>
      <sb-divider title="Recente zelfstandige oefeningen" />
      <div class="sb_coach_dashboard_indent">
        <p class="sb_coach_dashboard_annotation">
          Laatste {{ activeStudentsFetchLimit }} leerlingen die zelfstandig een
          kaart hebben geoefend.
        </p>
        <sb-table-2
          :config="recentCardExercisesTableConfig"
          :data="recentCardExercises || []"
          :loading="$apollo.queries.recentCardExercises.loading"
          :with-navigation="false"
        />
      </div>
    </template>

    <sb-spacer height="400" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbDivider from '@/components/SbDivider.vue';
import SbCallOut from '@/components/SbCallOut';
import SbTable2 from '@/components/SbTable2/SbTable2.vue';
import SbCreateTrackForm from '@/components/SbCreateTrackForm.vue';
import GetStudentsTrackStates from '@/graphql/queries/GetStudentsTrackStates.gql';
import UserTracks from '@/graphql/queries/UserTracks.gql';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from '@/components/SbTable2/TableConfig';
import { trackManagerMixin } from '@/mixins/trackManagerMixin';
import { quickStartInfoMixin } from '@/mixins/quickStartInfoMixin';
import gql from 'graphql-tag';
import SbCard from '@/components/SbCard.vue';
import SbTooltipInfo from '@/components/SbTooltipInfo.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    SbDivider,
    SbPageHeader,
    SbTable2,
    SbCreateTrackForm,
    SbCallOut,
    SbCard,
    SbTooltipInfo,
  },

  mixins: [
    quickStartInfoMixin,
    trackManagerMixin(),
    nonReactiveMembersMixin((self) => {
      self.activeStudentsFetchLimit = 10;

      const userColumns = [
        {
          key: 'fullName',
          header: 'Leerling',
          sortable: true,
          width: 200,
          filter: 'search',
          action: true,
        },
        {
          key: 'classYear',
          header: 'Groep',
          sortable: true,
          data: (row) =>
            row.classYear
              ? ['Groep', row.classYear.split('_')[1]].join(' ')
              : '-',
        },
        {
          key: 'email',
          header: 'E-mailadres',
          sortable: true,
        },
        {
          key: 'birthDate',
          header: 'Geboortedatum',
          sortable: true,
          data: (row) =>
            row.birthDate ? new Date(row.birthDate).toLocaleDateString() : '-',
        },
      ];

      return {
        recentCardExercisesTableConfig: new TableConfig({
          columns: [
            {
              key: 'createdBy',
              header: 'Leerling',
              data: (row) => row.createdBy.fullName,
            },
            {
              key: 'createdAt',
              header: 'Datum',
              data: (row) => {
                const date = new Date(row.createdAt);
                return [
                  date.toLocaleDateString(),
                  date.toLocaleTimeString(),
                ].join(', ');
              },
            },
            {
              key: 'card',
              header: 'Kaart',
              width: 300,
              data: (row) => row.card.title,
            },
            {
              // NO FIX FOR THIS
              key: 'readingLevel',
              header: 'Leesniveau',
              width: 130,
              data: (row) => row.readingLevel,
            },
            {
              key: 'finishedAt',
              header: 'Status',
              width: 130,
              data: (row) => (row.finishedAt ? 'Voltooid' : 'Bezig'),
            },
          ],
        }),

        recentlyActiveStudentsTableConfig: new TableConfig({
          columns: [
            ...userColumns.map((col) => ({ ...col, sortable: false })),
            {
              key: 'lastActivityAt',
              header: 'Laatst actief',
              data: (row) => {
                let date;
                if (!row.lastActivityAt) return '-';

                date = new Date(row.lastActivityAt);

                return [
                  date.toLocaleDateString(),
                  date.toLocaleTimeString(),
                ].join(', ');
              },
            },
          ],
        }),

        studentsWithoutActiveTrackTableConfig: new TableConfig({
          rowActions: [(row) => ['activate-track', 'Activeer een traject']],
          columns: userColumns,
        }),
      };
    }),
  ],

  data() {
    return {
      createTrackForm: {
        startDate: new Date(),
        endDate: undefined,
        isReadingComprehension: undefined,
      },
      showCreateTrackModal: false,
      showCreatorModal: false,
      showTrackModal: false,
      showNoTrackModal: false,
      trackId: undefined,
      newTrackStudentId: undefined,
      selectedGroupsAndStudents: [],
      studentSearchQuery: '',
      currentStudent: undefined,
      tooltipSession:
        'Hier kun je een sessie starten met een individuele leerling of met meerdere leerlingen tegelijk. Selecteer na klik de leerling(en) of de groep waarmee je de sessie wil doen. Let op: Je kunt alleen een sessie doen met leerlingen waarvoor een traject is aangemaakt. Als een leerling nog niet zichtbaar is in de selectielijst, maak dan eerst een actief traject voor deze leerling aan.',
      tooltipTrack:
        'Hier kun je een traject aanmaken voor een leerling. Na klik op de knop kun je een leerling selecteren. Vervolgens kun je optioneel toetsresultaten en focuspunten toevoegen aan het traject. Als je dit niet nodig vindt dan kun je deze stappen overslaan met de knop "Overslaan" onderaan het scherm. Je kunt pas sessies doen met een leerling nadat je voor de leerling een actief traject hebt aangemaakt.',
    };
  },

  computed: {
    createTrackCandidateStudents() {
      return this.getStudentsTrackStates
        ?.slice()
        .sort((a, b) =>
          a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : -1,
        );
    },

    activeStudents() {
      return this.getStudentsTrackStates
        ? this.getStudentsTrackStates.filter(
            (student) => !student.noTrack && !student.noActiveTrack,
          )
        : [];
    },

    preparationStudents() {
      return this.getStudentsTrackStates
        ? this.getStudentsTrackStates.filter(
            (student) => student.noTrack || student.noActiveTrack,
          )
        : [];
    },
    hasStudents() {
      return (this.getStudentsTrackStates?.length ?? 0) > 0;
    },

    groups() {
      const groups = JSON.parse(JSON.stringify(this.getStudentsTrackStates))
        .reduce((prev, cur) => {
          const group = prev.find((group) => {
            return group.id === cur.group.id;
          });

          if (group) {
            group.students.push(cur);
            return prev;
          }

          prev.push({
            ...cur.group,
            students: [cur],
          });

          return prev;
        }, [])
        .map((group) => {
          group.hasAvailableStudents = !!group.students.filter(
            (student) => !student.noTrack && !student.noActiveTrack,
          ).length;
          return group;
        });

      groups.sort((a, b) => {
        if (a.hasAvailableStudents === b.hasAvailableStudents) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        }

        return a.hasAvailableStudents ? -1 : 1;
      });

      return groups || [];
    },

    selectedStudents() {
      const groupIds = [];
      const studentIds = new Set();

      this.selectedGroupsAndStudents.forEach((entry) => {
        const [type, id] = entry.split('_');
        if (type === 'GROUP') groupIds.push(id);
        if (type === 'STUDENT') studentIds.add(id);
      });

      groupIds.forEach((groupId) => {
        const group = this.groups.find((group) => group.id === groupId);
        if (!group) return;
        group.students.forEach((student) => {
          if (student.noTrack || student.noActiveTrack) return;
          studentIds.add(student.id);
        });
      });

      return Array.from(studentIds);
    },

    studentSearchResults() {
      const query = this.studentSearchQuery.toLowerCase();
      const results = this.students?.filter((student) => {
        const match = [
          student.fullName?.toLowerCase().includes(query),
          student.username?.toLowerCase().includes(query),
          student.email?.toLowerCase().includes(query),
        ].includes(true);

        return match;
      });

      return results;
    },
  },

  mounted() {
    this.$apollo.queries.getStudentsTrackStates.refresh();
    const currentTourKey = 'COACH_ACCOUNT';
    this.enqueueTour(currentTourKey);
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
    sortSessionStudents(students) {
      return students.slice().sort((a, b) => {
        const aNoActiveTrack = a.noTrack || a.noActiveTrack;
        const bNoActiveTrack = b.noTrack || b.noActiveTrack;

        if (aNoActiveTrack === bNoActiveTrack) {
          return a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : -1;
        }

        return aNoActiveTrack ? 1 : -1;
      });
    },

    handleCreateTrackSubmit(studentId = this.newTrackStudentId) {
      if (!studentId) return;
      this.$router.push({
        name: 'ResourceManagementStudentsStudentPaths',
        params: {
          ...this.$route.params,
          studentId,
        },
        query: {
          createTrack: true,
        },
      });
    },

    goToStudent({ id }) {
      this.$router.push({
        name: 'ResourceManagementStudentsStudent',
        params: {
          ...this.$route.params,
          studentId: id,
        },
      });
    },

    createStudent() {
      this.$router.push({
        name: 'ResourceManagementStudentAdd',
      });
    },

    getStudentsFromGroup(group) {
      return group.students.edges
        .filter(({ node: user }) => {
          return !!this.trackManagerData?.studentActiveTrackLookup?.[user.id];
        })
        .map(({ node }) => node);
    },

    createSession() {
      const _studentIds = this.selectedStudents;
      const _trackIds = this.selectedStudents.map((studentId) => {
        const student = this.activeStudents.find(
          (student) => student.id === studentId,
        );
        return student.currentActiveTrack.id;
      });

      if (_studentIds.length !== _trackIds.length) {
        throw new Error(
          'Could not find active track for all selected students.',
        );
      }
      const studentIds = _studentIds.join(',');
      const trackIds = _trackIds.join(',');

      this.showCreatorModal = false;

      setTimeout(() => {
        this.$router.push({
          name: 'Session',
          query: { studentIds, trackIds },
        });
      }, 300);
    },

    async handleStudentRowAction(student) {
      console.log({ student });
      const { data } = await this.$apollo.query({
        query: UserTracks,
        variables: {
          id: student.id,
          tracksFilter: {},
        },
      });
      this.currentStudent = data.getUserById;
      if (student.noTrack) {
        this.showNoTrackModal = true;
        return;
      }
      if (student.noActiveTrack) {
        this.showTrackModal = true;
      }
    },

    getTrackOptionLabel(track) {
      const date = new Date(track.startDate);
      return [
        track.readingLevels || '',
        `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
      ]
        .filter(Boolean)
        .join(' - ');
    },

    async handleTrackSubmit() {
      if (!this.currentStudent.id || !this.trackId) return;
      await this.activateTrack(this.trackId, this.currentStudent.id, true);
      this.$router.push({
        name: 'ResourceManagementStudentsStudentPathOverview',
        params: {
          ...this.$route.params,
          studentId: this.currentStudent.id,
          path: this.trackId,
        },
      });
    },

    async handleCreateTrack() {
      if (!this.createTrackForm.startDate) {
        return setTimeout(
          () =>
            this.$Modal.warning({
              title: 'Foutieve invoer',
              content: 'Voer een startdatum in.',
            }),
          300,
        );
      }

      const data = await this.createTrack(
        this.currentStudent.id,
        this.createTrackForm,
      );

      // this.showTrackModal = false;
      if (!data) return;

      setTimeout(() => {
        this.$router.push({
          name: 'ResourceManagementStudentsStudentPathAddTestResult',
          params: {
            studentId: this.currentStudent.id,
            path: data.createTrack.id,
          },
          query: {
            newTrack: data.createTrack.id,
          },
        });
      }, 300);
    },
  },

  apollo: {
    recentCardExercises: {
      variables() {
        return {
          first: 10,
          filter: {
            sessionId: { equals: null },
          },
          orderBy: { createdAt: 'DESC' },
        };
      },

      update(data) {
        return data.cardExercisesPaginated.edges.map(({ node }) => node);
      },

      query: gql`
        query CoachDashboard_RecentCardExercises(
          $first: Int
          $filter: CardExercisesFilter
          $orderBy: CardExercisesOrderBy
        ) {
          cardExercisesPaginated(
            first: $first
            filter: $filter
            orderBy: $orderBy
          ) {
            edges {
              node {
                id
                createdAt
                finishedAt
                readingLevel
                sessionId
                createdBy {
                  id
                  fullName
                }
                card {
                  id
                  number
                  title
                }
              }
            }
          }
        }
      `,
    },

    getStudentsTrackStates: {
      query: GetStudentsTrackStates,
      fetchPolicy: 'cache-and-network',
    },

    studentsWithRecentActivity: {
      fetchPolicy: 'cache-and-network',

      variables() {
        return {
          first: this.activeStudentsFetchLimit,
          filter: { hasActiveTrack: true, isActive: true },
          orderBy: { lastActivityAt: 'DESC' },
        };
      },

      update(data) {
        return data.me.coachingStudents.edges.map(({ node }) => node);
      },

      query() {
        return gql`
          query Dashboard_RecentlyActiveStudents(
            $first: Int
            $filter: UsersFilter
            $orderBy: UsersOrderBy
          ) {
            me {
              id
              coachingStudents(
                first: $first
                filter: $filter
                orderBy: $orderBy
              ) {
                edges {
                  node {
                    id
                    fullName
                    classYear
                    birthDate
                    email
                    lastActivityAt
                  }
                }
              }
            }
          }
        `;
      },
    },
  },
};
</script>

<style lang="scss">
.no-active-track {
  color: $brand-red !important;
}

.sb_coach-dashboard_container {
  position: relative;
}

.sb_coach_dashboard {
  &_quick-menu {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    gap: 1.5rem;

    &_card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &_card-title {
      margin-bottom: 0.8rem;
    }
  }

  &_indent {
    padding-left: 20px;
  }

  &_annotation {
    color: $brand-gray;
  }
}
</style>
