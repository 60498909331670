<template>
  <div>
    <!-- <Breadcrumb>
      <BreadcrumbItem>Dashboard</BreadcrumbItem>
    </Breadcrumb> -->

    <sb-page-header>
      <template v-slot:left>
        <h1>Dashboard</h1>
      </template>
      <template v-slot:right> </template>
    </sb-page-header>

    <div class="sb_view_online-dashboard_kpis" style="max-width: 1200px">
      SchoolDistrict Dashboard
      <sb-spacer height="100" />
    </div>
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';

export default {
  components: {
    SbPageHeader,
  },
};
</script>

<style lang="scss">
.sb_view_online-dashboard {
  //
  //  KPIS
  //
  &_kpis {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 20px;

    &_item {
      position: relative;
      display: flex;
      padding: 40px 0;
      overflow: hidden;
      background: $brand-lightest-gray;
      border: solid 1px $brand-light-gray;
      border-radius: 10px;

      &_icon {
        position: absolute;
        top: 0;
        left: -40px;

        svg {
          font-size: 200px;
          opacity: 0.05;
        }
      }

      &_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 30px;
        user-select: none;
      }

      &_title {
      }

      &_subtitle {
        font-size: 14px;
        color: $brand-gray;
      }

      &_value {
        margin: 20px 0 0 0;
        font-size: 40px;
        font-weight: 600;

        &.v_vibe-red {
          color: $brand-vibe-red;
        }

        &.v_vibe-green {
          color: $brand-vibe-green;
        }

        &.v_vibe-blue {
          color: $brand-primary;
        }
      }

      &_left {
        width: 50%;
        max-width: 50%;
        border-right: solid 1px $brand-light-gray;
      }

      &_right {
        width: 50%;
        max-width: 50%;
      }
    }
  }
}

button.sb_view_online-dashboard_kpis_item_inner {
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.5;
  }
}
</style>
