import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { enumFromArray } from './array-to-enum';

export const CoachType = Object.freeze({
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
});

/**
 * Get a human readable label for the corresponding `CoachType` member.
 * @param {string} type
 * @returns {string} access request status label
 */
export function getCoachTypeLabel(type) {
  if (!CoachType[type]) {
    throw new TypeError(`Unknown CoachType type \`${type}\``);
  }

  switch (type) {
    case CoachType.INTERNAL:
      return 'Intern';
    case CoachType.EXTERNAL:
      return 'Extern';
  }
}

export const coachTypeMixin = Object.freeze({
  mixins: [nonReactiveMembersMixin(() => ({ CoachType }))],
  methods: { getCoachTypeLabel },
});
