<template>
  <sb-call-out style="width: 300px">
    <template v-slot:left> {{ emoji }} </template>
    <template v-slot:right>
      Aantal actieve <slot>{{ label }}:</slot>
      <br />
      <strong style="font-size: 24px">
        {{ amount }}
      </strong>
    </template>
  </sb-call-out>
</template>

<script>
import SbCallOut from '@/components/SbCallOut';

export default {
  components: { SbCallOut },
  props: {
    emoji: { type: String, default: '👨‍🎓' },
    label: { type: String, default: 'gebruikers' },
    amount: { type: [Number, String], default: 0 },
  },
};
</script>

<style lang="scss" scoped></style>
